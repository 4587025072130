import { createSlice } from 'redux-starter-kit';

const appSlice = createSlice({
  slice: 'app',
  initialState: {
    initialRender: true,
  },
  reducers: {
    setInitialRender (state, action) {
      const isTrue = action.payload;
      if (isTrue !== undefined) {
        state.initialRender = isTrue;
      };
    },
  }
});

export default appSlice.reducer;
export const { setInitialRender } = appSlice.actions;
