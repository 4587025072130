import fetch from 'isomorphic-unfetch';
import { createSlice } from 'redux-starter-kit';
import { convertJSONToObj } from '../../util/index';
import { apiAddress } from '../../app/config';
import { setIsLoading } from '../loadingSpinner/loaderSlice';

const metroSlice = createSlice({
  slice: 'metros',
  initialState: {
    array: [],
    byId: {},
    current: '',
  },
  reducers: {
    setMetros (state, action) {
      const metros = action.payload.map(metro => ({ ...metro, 
        nameExpanded: metro.name + ', ' + metro.province_state,
      }));
      if (metros) {
        state.array = metros,
        state.byId = convertJSONToObj(metros, "id");
      };
    },
    setCurrentMetro (state, action) {
      const current = action.payload;
      if (current) {
        state.current = current;
      };
    },
  },
});

metroSlice.getAndSetMetros = () => async (dispatch, getState) => {
  let isDefinedInStore = Object.entries(getState().metros.array).length;
  if (!isDefinedInStore) {
    // console.log("Found no metros in store --> fetching metros")
    await dispatch(setIsLoading(true));
    const res = await fetch(apiAddress('/metros/'));
    const data = await res.json();
    await dispatch(setIsLoading(false));
    await dispatch(setMetros(data));
  };
};

export default metroSlice.reducer;
export const { setMetros, setCurrentMetro } = metroSlice.actions;
export const { getAndSetMetros } = metroSlice;
