const apiRootLocal = 'http://localhost:3000';
const apiRootWeb = 'https://api.marriedthere.com';
const apiUseLocal = false;

const imageRootLocal = '/static/s3';
const imageRootWeb = 'https://d1mn3ypsfmaab4.cloudfront.net';
const imageUseLocal = false;

const googleMapsEmbedKeyLocal = 'AIzaSyB9BF21c91L6V9AFQg3eUk_BCvDwWHqHtY';
const googleMapsEmbedKeyWeb = 'AIzaSyA4rMHnauzugNC7GC8PDXApGGPTJhZHVJM';
const googleMapsEmbedKeyUseLocal = false;
  
export const apiAddress = path => {
  if (apiUseLocal) {
    return apiRootLocal + path;
  } else {
    return apiRootWeb + path;
  }
};

export const imageAddress = path => {
  if (imageUseLocal) {
    return imageRootLocal + path;
  } else {
    return imageRootWeb + path;
  }
};

export const getGoogleMapsEmbedKey = () => {
  if (googleMapsEmbedKeyUseLocal) {
    return googleMapsEmbedKeyLocal;
  } else {
    return googleMapsEmbedKeyWeb;
  }
};
