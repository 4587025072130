import { createSlice } from 'redux-starter-kit';

const loaderSlice = createSlice({
  slice: 'loader',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setIsLoading (state, action) {
      state.isLoading = action.payload;
    },
  },
});

export default loaderSlice.reducer;
export const { setIsLoading } = loaderSlice.actions;
