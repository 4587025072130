import createFilterSlice from '../venueFilter/filterSlice';

const neighbourhoodSlice = createFilterSlice("neighbourhood");

export const setNeighbourhoods = neighbourhoods => async (dispatch, getState) => {
  const neighbourhoodsDecorated = neighbourhoods.map(neighbourhood => ({ ...neighbourhood, 
    nameExpanded: neighbourhood.name + ', ' + getState().metros.byId[neighbourhood.metroId].province_state,
  }));
  dispatch(neighbourhoodSlice.actions.setNeighbourhoods(neighbourhoodsDecorated));
};

export const { toggleNeighbourhoodChecked, setNeighbourhoodsSelected, checkNeighbourhoods, setNeighbourhoodsChecked } = neighbourhoodSlice.actions;

export const { getAndSetNeighbourhoods, setNeighbourhoodsSelectedFromChecked } = neighbourhoodSlice;

export default neighbourhoodSlice.reducer;
