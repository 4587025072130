import fetch from 'isomorphic-unfetch';
import { createSlice } from 'redux-starter-kit';
import { convertJSONToObj } from '../../util/index';
import { apiAddress } from '../../app/config';
import { setIsLoading } from '../loadingSpinner/loaderSlice';

const allNeighbourhoodsSlice = createSlice({
  slice: 'allNeighbourhoods',
  initialState: {
    array: [],
    byId: {},
  },
  reducers: {
    setAllNeighbourhoods (state, action) {
      const neighbourhoods = action.payload;
      if (neighbourhoods) {
        state.array = neighbourhoods,
        state.byId = convertJSONToObj(neighbourhoods, "id");
        state.byMetro = neighbourhoods.reduce((obj, neighbourhood) => {
          if (!obj[neighbourhood.metroId]) {
            obj[neighbourhood.metroId] = [neighbourhood]
          } else {
            obj[neighbourhood.metroId].push(neighbourhood);
          };
          return obj;
        }, {})
      };
    },
  }
});

allNeighbourhoodsSlice.getAndSetAllNeighbourhoods = () => async (dispatch, getState) => {
  let isDefinedInStore = Object.entries(getState().allNeighbourhoods.array).length;
  if (!isDefinedInStore) {
    // console.log(`Found allNeighbourhoods empty in store --> fetching`);
    await dispatch(setIsLoading(true));
    const res = await fetch(apiAddress('/neighbourhoods/all'));
    const data = await res.json();
    await dispatch(setIsLoading(false));
    await dispatch(setAllNeighbourhoods(data));
  }; 
};

export default allNeighbourhoodsSlice.reducer;
export const { getAndSetAllNeighbourhoods } = allNeighbourhoodsSlice;

export const setAllNeighbourhoods = neighbourhoods => async (dispatch, getState) => {
  const neighbourhoodsDecorated = neighbourhoods.map(neighbourhood => ({ ...neighbourhood, 
    nameExpanded: neighbourhood.name + ', ' + getState().metros.byId[neighbourhood.metroId].province_state,
  }));
  dispatch(allNeighbourhoodsSlice.actions.setAllNeighbourhoods(neighbourhoodsDecorated));
};
