import fetch from 'isomorphic-unfetch';
import { createSlice } from 'redux-starter-kit';
import { convertJSONToObj, capitalize, convertItemsCheckedToItemsSelected } from '../../util/index';
import { apiAddress } from '../../app/config';
import { setIsLoading } from '../loadingSpinner/loaderSlice';

const createFilterSlice = (filterName, extendInitialState={}, extendReducers={}) => {
  let capitalizedFilterName = capitalize(filterName);
  let stateId = filterName + 's';

  const sliceParams = {
    slice: filterName,
    initialState: {
      array: [],
      byId: {},
      selected: [],
      totalFilterCount: 0,
      appliedFilterDisplay: "",
    },
    reducers: {}
  };

  sliceParams.reducers['set' + capitalizedFilterName + 's'] = (state, action) => {
    const array = action.payload;
    const itemsSelected = [];
    if (array) {
      state.array = array;
      state.byId = convertJSONToObj(array, "id", {
        checked: false,
        selected: true
      });
      state.totalFilterCount = array.length;
      array.forEach(item => itemsSelected.push(item.id));
      state.selected = itemsSelected;
    };
  };

  sliceParams.reducers['toggle' + capitalizedFilterName + 'Checked'] = (state, action) => {
    const id = action.payload;
    state.byId[id].checked = !state.byId[id].checked;
  };

  sliceParams.reducers['set' + capitalizedFilterName + 'sChecked'] = (state, action) => {
    const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];
    const itemsChecked = payloadArray.reduce((obj, item) => {
      obj[item] = true;
      return obj;
    }, {});
    for (var itemId in state.byId) {
      if (itemsChecked[itemId]) {
        state.byId[itemId].checked = true;
      } else {
        state.byId[itemId].checked = false;
      };
    };
  };

  sliceParams.reducers['check' + capitalizedFilterName + 's'] = (state, action) => {
    if (Array.isArray(action.payload)) {
      const ids = action.payload;
      ids.forEach(id => {
        state.byId[id].checked = true;
      });
    } else {
      const id = action.payload;
      state.byId[id].checked = true;
    }
  };

  sliceParams.reducers['set' + capitalizedFilterName + 'sSelected'] = (state, action) => {
    const itemsSelected = action.payload;
    const totalFilterCount = state.totalFilterCount;
    let selectedCount = 0;
    let selectedArray = [];
    for (var id in itemsSelected) {
      state.byId[id].selected = itemsSelected[id].selected;
      if (itemsSelected[id].selected) {
        selectedCount++;
        selectedArray.push(id);
      };
    };
    state.selected = selectedArray;
    if (selectedCount === totalFilterCount || selectedCount === 0) {
      state.appliedFilterDisplay = "";
    } else {
      state.appliedFilterDisplay = `- ${selectedCount}`;
    };
  }; 

  Object.keys(extendInitialState).forEach(key => {
    sliceParams.initialState[key] = extendInitialState[key];
  });

  Object.keys(extendReducers).forEach(key => {
    sliceParams.reducers[key] = extendReducers[key];
  });

  const slice = createSlice(sliceParams);

  slice['getAndSet' + capitalizedFilterName + 's'] = (setFilter, urlPath) => async (dispatch, getState) => {
    let isDefinedInStore = Object.entries(getState()[stateId].array).length;
    if (!isDefinedInStore) {
      // console.log(`Found no ${filterName} in store --> fetching`);
      await dispatch(setIsLoading(true));
      const res = await fetch(apiAddress(urlPath));
      const data = await res.json();
      await dispatch(setIsLoading(false));
      await dispatch(setFilter(data));
    }; 
  };

  slice['set' + capitalizedFilterName + 'sSelectedFromChecked'] = setSelectedFunc => async (dispatch, getState) => {
    const data = getState()[stateId].byId;
    const itemsSelected = convertItemsCheckedToItemsSelected(data);
    let isServerPollRequired = false;
    for (var id in data) {
      if (itemsSelected[id].selected !== data[id].selected) {
        isServerPollRequired = true;
      };
    };
    dispatch(setSelectedFunc(itemsSelected));
    return isServerPollRequired;
  };

  return slice;
};

export default createFilterSlice;
