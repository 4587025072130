import fetch from 'isomorphic-unfetch';
import { createSlice } from 'redux-starter-kit';
import { convertJSONToObj, createMultiValueQueryString, decorateVenueWithImages } from '../../util/index';
import { apiAddress } from '../../app/config';
import { setIsLoading } from '../loadingSpinner/loaderSlice';

const venueSlice = createSlice({
  slice: 'venues',
  initialState: {
    all: {
      array: [],
      byId: {},
    },
    detailed: {
      array: [],
      byId: {},
    },
    currentDetailed: {},
    currentPage: 1,
  },
  reducers: {
    setDetailedVenues (state, action) {
      const venues = action.payload;
      if (venues) {
        if (venues.length) {
          venues.forEach(venue => decorateVenueWithImages(venue));
        };
        state.detailed.array = venues;
        state.detailed.byId = convertJSONToObj(venues, "id");
      };
    },
    setCurrentDetailed (state, action) {
      const venueDetails = action.payload;
      if (venueDetails) {
        state.currentDetailed = venueDetails;
      };
    },
    setAllVenues (state, action) {
      const venues = action.payload;
      if (venues) {
        state.all.array = venues;
        state.all.byId = convertJSONToObj(venues, "id");
      };
    },
    setCurrentPage (state, action) {
      const page = action.payload;
      if (page) {
        state.currentPage = page;
      }
    }
  }
});

export const { setDetailedVenues, setAllVenues, setCurrentPage, setCurrentDetailed } = venueSlice.actions;

export default venueSlice.reducer;

export const createQueryStringFromRouter = query => async (dispatch, getState) => {
  const venueParams = {};
  const metros = await getState().metros.byId;
  const allNeighbourhoods = await getState().allNeighbourhoods.byId;
  const isLocationMetro = !!metros[query.location];
  const isLocationNeighbourhood = !!allNeighbourhoods[query.location];

  if (isLocationMetro) {
    venueParams.metroId = [query.location];
    if (query.neighbourhood) {
      venueParams.neighbourhoodId = Array.isArray(query.neighbourhood) ? query.neighbourhood : [query.neighbourhood];
    };
    if (query.setting) {
      venueParams.settingIds = Array.isArray(query.setting) ? query.setting : [query.setting];
    };
  } else if (isLocationNeighbourhood) {
    venueParams.metroId = [allNeighbourhoods[query.location].metroId];
    venueParams.neighbourhoodId = [query.location];
    if (query.setting) {
      venueParams.settingIds = Array.isArray(query.setting) ? query.setting : [query.setting];
    };
  } else {
    const queryString = await dispatch(createQueryStringFromFilters());
    return queryString;
  };
  return createMultiValueQueryString(venueParams);
};

export const createQueryStringFromFilters = () => async (dispatch, getState) => {
  const metroId = await getState().metros.current;
  const venueParams = { metroId: [metroId] };
  const decorateVenueParams = (paramName, filterData) => {
    let isNoSpecificFilterSelected = true;
    for (var id in filterData) {
      if (filterData[id].selected) {
        if (!venueParams[paramName]) {
          venueParams[paramName] = [];
        };
        venueParams[paramName].push(id);
      } else {
        isNoSpecificFilterSelected = false;
      }
    };
    if (isNoSpecificFilterSelected) {
      delete venueParams[paramName];
    }
  };
  const neighbourhoods = await getState().neighbourhoods.byId;
  const settings = await getState().settings.byId;
  decorateVenueParams('neighbourhoodId', neighbourhoods);
  decorateVenueParams('settingIds', settings);
  return createMultiValueQueryString(venueParams);
};

export const getAndSetVenues = (shouldPollServer, page, query) => async (dispatch, getState) => {
  page = page ? page : getState().venues.currentPage;
  let personalRatingTypesById = getState().personalRatingTypes.byId; 
  let personalRatingsById = getState().personalRatings.byId; 

  personalRatingTypesById = getState().personalRatingTypes.byId; 
  const selectedPersonalRatings = [];
  
  for (var ratingType in personalRatingTypesById) {
    const ratingTypeName = personalRatingTypesById[ratingType].name;
    const isSelected = personalRatingTypesById[ratingType].selected;
    if (isSelected) {
      selectedPersonalRatings.push(ratingTypeName);
    };
  };

  const isPersonalRatingUnfiltered = selectedPersonalRatings.length === 0;
  const queryString = await dispatch(createQueryStringFromRouter(query));
    
  if (shouldPollServer !== false) {
    await dispatch(setIsLoading(true));
    const resAll = await fetch(apiAddress(`/venues?${queryString}page=${page}`));
    const venuesAll = await resAll.json();
    const venuesAllFiltered = isPersonalRatingUnfiltered ? venuesAll : venuesAll.reduce((array, venue) => {
      const isUnmarked = !personalRatingsById[venue.id];
      if (personalRatingTypesById.unmarked.selected && isUnmarked) {
        return [...array, venue];
      }
      const hasSelectedMark = selectedPersonalRatings.includes(personalRatingsById[venue.id]);
      if (hasSelectedMark) {
        return [...array, venue];
      }
      return array;
    }, [])
    dispatch(setAllVenues(venuesAllFiltered));

    let resDetailed;
    const isVenueListEmpty = venuesAllFiltered.length === 0;
    if (isVenueListEmpty) {
      dispatch(setDetailedVenues([]));
      dispatch(setCurrentPage(0));
    } else {
      // instead of querying by specific venueIds, get all for that neighbourhood & setting
      // const startVenueIndex = (page < 1 ? 0 : page-1) * 10;
      // const endVenueIndex = startVenueIndex + 10;
      // const venueParamsSpecificIds = venuesAllFiltered.slice(startVenueIndex, endVenueIndex).reduce((params, venue) => {
      //   params.id.push(venue.id);
      //   return params;
      // }, { id: [] });
      // const queryStringSpecificIds = createMultiValueQueryString(venueParamsSpecificIds);
      resDetailed = await fetch(apiAddress(`/venues/detailed?${queryString}`));
      const venuesDetailed = await resDetailed.json();
      dispatch(setDetailedVenues(venuesDetailed));
      dispatch(setCurrentPage(page));
    };
    await dispatch(setIsLoading(false));
  };
};

export const getAndSetCurrentDetailed = id => async (dispatch, getState) => {
  const currentDetailed = getState().venues.currentDetailed;
  const venuesDetailed = getState().venues.detailed;
  if (currentDetailed.id !== id) {
    if (venuesDetailed[id]) {
      dispatch(setCurrentDetailed(venuesDetailed[id]));
    } else {
      const res = await fetch(apiAddress(`/venues/detailed?id=${id}`));
      const data = await res.json();
      const venue = decorateVenueWithImages(data[0]);
      dispatch(setCurrentDetailed(venue));
    }
  }
};

export const getRevisedRouterQuery = (query, queryParamName, queryParamItemsSelected) => async => {
  let itemsFiltered = [];
  let isAllFiltered = true;
  const newQuery = {...query};
  newQuery[queryParamName] = [];

  for (var itemId in queryParamItemsSelected) {
    const item = queryParamItemsSelected[itemId];
    if (item.selected) {
      itemsFiltered.push(itemId);
    } else {
      isAllFiltered = false;
    }
  };
  if (isAllFiltered) {
    itemsFiltered = [];
  } else if (itemsFiltered.length === 1) {
    itemsFiltered = itemsFiltered[0];
  };
  
  const isSomeFiltered = itemsFiltered.length > 0;
  if (isSomeFiltered) {
    newQuery[queryParamName] = itemsFiltered;
  };
  return newQuery;
};
