import createFilterSlice from '../venueFilter/filterSlice';
import { convertJSONToObj } from '../../util/index';

const array = [
  { id: 'no', name: 'No' },
  { id: 'maybe', name: 'Maybe' },
  { id: 'yes', name: 'Yes' },
  { id: 'unmarked', name: 'Unmarked' },
];

const byId = convertJSONToObj(array, "id", {
  checked: false,
  selected: true,
});

const extendInitialState = {
  array,
  byId,
  totalFilterCount: 4,
};

const personalRatingTypeSlice = createFilterSlice('personalRatingType', extendInitialState);

export default personalRatingTypeSlice.reducer;
export const { togglePersonalRatingTypeChecked, setPersonalRatingTypesSelected, setHasFetchedFromLocalStorage, setPersonalRatingTypesChecked } = personalRatingTypeSlice.actions;
export const { setPersonalRatingTypesSelectedFromChecked } = personalRatingTypeSlice;
