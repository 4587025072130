import { createSlice } from 'redux-starter-kit';
import { storageAvailable } from '../../util/index';

const personalRatingSlice = createSlice({
  slice: 'personalRatings',
  initialState: {
    byId: {},
    hasFetchedFromLocalStorage: false,
  },
  reducers: {
    setHasFetchedFromLocalStorage (state, action) {
      const hasFetched = action.payload;
      if (hasFetched) {
        state.hasFetchedFromLocalStorage = hasFetched;
      };
    },
    modifyPersonalRatings (state, action) {
      const { shouldUpdateLocalStorage, data } = action.payload;
      const localStorage = window.localStorage;
      if (data) {
        Object.keys(data).forEach(venueId => {
          if (state.byId[venueId] === data[venueId]) {
            delete state.byId[venueId];
          } else {
            state.byId[venueId] = data[venueId];
          };
          if (storageAvailable('localStorage')) {
            if (shouldUpdateLocalStorage && localStorage.getItem(venueId) === data[venueId]) {
              localStorage.removeItem(venueId);
            } else {
              localStorage.setItem(venueId, data[venueId]);
            };
          };
        });
      };
    },
  }
});

export default personalRatingSlice.reducer;
export const { modifyPersonalRatings, setHasFetchedFromLocalStorage } = personalRatingSlice.actions;

export const fetchPersonalRatingsFromLocalStorage = () => async dispatch => {
  if (storageAvailable('localStorage')) {
    const localStorage = window.localStorage;
    const ratingOptions = ["No", "Maybe", "Yes"];
    const data = {};
    Object.keys(localStorage).forEach(key => {
      const isRating = ratingOptions.some(option => option === localStorage[key]);
      if (isRating) {
        data[key] = localStorage[key];
      };
    });
    dispatch(modifyPersonalRatings({
      data,
      shouldUpdateLocalStorage: false,
    }));
  };
  dispatch(setHasFetchedFromLocalStorage(true));
};
