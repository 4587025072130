import { imageAddress } from '../app/config';
import 'url-polyfill';

export const convertJSONToObj = (arr = [], key, newProps = {}) => {
  return arr.reduce((obj, element) => {
    obj[element[key]] = element;
    for (var newPropsKey in newProps) {
      obj[element[key]][newPropsKey] = newProps[newPropsKey];
    };
    return obj;
  }, {});
};

export const createMultiValueQueryString = ( params = {} ) => {
  let output = "";
  for (var key in params) {
    params[key].forEach(value => {
      output = output + key + '=' + value + '&';
    });
  }
  return output;
};

export const convertItemsCheckedToItemsSelected = itemsChecked => {
  let checkedCount = Object.values(itemsChecked).reduce((count, item) => {
    if (item.checked) {
      return count++;
    };
  }, 0);
  let itemsSelected = {};

  for (var id in itemsChecked) {
    itemsSelected[id] = {};
    if (checkedCount === 0) {
      itemsSelected[id].selected = true;
    } else {
      itemsSelected[id].selected = itemsChecked[id].checked;
    }
  };

  return itemsSelected;
};

export const createImagesArray = (metroId, venueId, photographerId, coupleId, photoCount) => {
  const dirPath = '/' + metroId + '/' + venueId + '/'+ photographerId + '/' + coupleId + '/';
  let imagesArray = [];
  for (var i = 1; i <= photoCount; i++) {
    imagesArray.push(imageAddress(dirPath + i + '.jpg'));
  };
  return imagesArray;
}

export const createPreviewArray = (array, shootLookup) => {
  const preview = [];
  array.forEach(image => {
    const shoot = shootLookup[image[0]];
    const imageNum = image[1];
    preview.push({
      src: shoot.path + imageNum + '.jpg', 
      couple: shoot.couple,
      photographer: shoot.photographer,
      externalUrl: shoot.externalUrl,
    });
  });
  return preview;
}

export const capitalize = string => {
  return string[0].toUpperCase() + string.substring(1);
}

export const decorateVenueWithImages = venue => {
  if (venue.photoshoots.length) {
    const shootLookup = {
      venue: {
        path: imageAddress('/' + venue.metroId + '/' + venue.id + '/venue/'),
        couple: '',
        photographer: 'Official Website',
        externalUrl: venue.officialUrl,
      },
    };
    venue.photoshoots.forEach(shoot => {
      if (!shootLookup[shoot.photoshootId]) {
        const dirPath = '/' + venue.metroId + '/' + venue.id + '/'+ shoot.photographerId + '/' + shoot.coupleId + '/';
        shootLookup[shoot.photoshootId] = {
          path: imageAddress(dirPath),
          couple: shoot.partner1 + ' & ' + shoot.partner2,
          photographer: shoot.photographer,
          externalUrl: shoot.externalUrl,
        };
      };
      shoot.images = createImagesArray(venue.metroId, venue.id, shoot.photographerId, shoot.coupleId, shoot.photoCount);
    });
    venue.preview = createPreviewArray(venue.preview, shootLookup);
  };
  return venue;
};

export const storageAvailable = type => {
  var storage;
  try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
  }
  catch(e) {
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          (storage && storage.length !== 0);
  }
};

export const setParamInUrl = (param, value, urlPath) => {
  const urlObj = new URL('https://marriedthere.com' + urlPath);
  const pathWithoutSearchParams = urlObj.pathname;
  const searchParams = urlObj.searchParams;
  searchParams.set(param, value);
  return pathWithoutSearchParams + '?' + searchParams.toString();
};

export const createStringFromPhotoshoots = photoshoots => {
  return photoshoots.reduce((string, shoot) => {
    const isStringEmpty = !string.length;
    const text = `${shoot.partner1} & ${shoot.partner2} (by ${shoot.photographer})`;
    if (isStringEmpty) {
      return text;
    };
    return string + ', ' + text;
  }, '');
}

module.exports.convertJSONToObj = convertJSONToObj;
